// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { ArrowNarrowRightIcon, BeakerIcon } from "@heroicons/react/solid"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TemplateIcon,
} from "@heroicons/react/outline"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../components/common/main-slider"
import AtglanceSlider from "../components/common/at-glance-slider"
import ReactPlayer from "react-player"

// sustainability icons

import people_icon from "../images/about/Sustainability/Icons/People.svg"
import plannet_goal_icon from "../images/about/Sustainability/Icons/Planet goals.svg"
import product_goal_icon from "../images/about/Sustainability/Icons/Product goals.svg"
import Story_slider from "../components/common/story-slider"

const About = ({ data }) => {
  const images = {
    hero: convertToBgImage(getImage(data.hero)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),

    //icon
    li_mint: getImage(data.li_mint),

    // your end to end carosoul
    concept_img: convertToBgImage(getImage(data.end_to_end_1)),
    configure_img: convertToBgImage(getImage(data.end_to_end_2)),
    industrial_img: convertToBgImage(getImage(data.end_to_end_3)),
    manufacture_img: convertToBgImage(getImage(data.end_to_end_4)),
    proprietary_img: convertToBgImage(getImage(data.end_to_end_5)),

    //
    dreem: convertToBgImage(getImage(data.dreem)),

    // team images
    Ulysses: getImage(data.Ulysses),

    //purpose
    purpose: convertToBgImage(getImage(data.purpose)),

    //award images
    award1: convertToBgImage(getImage(data.award1)),
    award2: convertToBgImage(getImage(data.award2)),

    //leadership images

    don: getImage(data.leadership1),
    ross: getImage(data.leadership2),
    tiasha: getImage(data.leadership3),
    ulysses: getImage(data.leadership4),
    vishwa: getImage(data.leadership5),

    //purpose images

    purpose3: convertToBgImage(getImage(data.purpose1)),
    purpose1: convertToBgImage(getImage(data.purpose2)),
    purpose2: convertToBgImage(getImage(data.purpose3)),

    //sustainability images
    asset53: convertToBgImage(getImage(data.Sustainability1)),
    process_goals: convertToBgImage(getImage(data.Sustainability2)),
    product_goals: convertToBgImage(getImage(data.Sustainability3)),

    //banner image

    banner: convertToBgImage(getImage(data.banner)),
  }

  const story = [
    {
      green_text: " We are here to change all that.",
      content1:
        "When was the last time you felt a sense of wonder when experiencing technology? When a real need was met by a solution that simply worked? It’s a rare feeling, isn’t it? We see this in our industry. Wearables become gimmicky, textiles become unsustainable and innovation becomes disconnected from need.",
    },

    {
      content1:
        "By weaving technology into textiles, we push the boundaries of what is imaginable – and accessible –  for our users, our partners and our industry. We amplify creative vision with manufacturing scale to redefine how technology feels to people. ",
    },
    {
      content1:
        "We’re driven by thoughtful momentum, where our inventiveness is rooted in real-world needs. From a moment of fun to a sigh of relief, we’re intrinsic to people’s lives.  ",
    },
    {
      content1:
        "We are a diverse global team spun from a rich common heritage. We combine the entrepreneurial spirit of a newcomer with the credibility of a veteran. From sketch to shelf, from technology to healthcare, and from startups to large enterprises, we are the partner of choice to integrate the smart with the soft at scale. ",
    },
    {
      content1:
        "As we bring technology closer to people, we are threading progress and empowerment into the fabric of the future.",
    },
  ]

  const sustainability = [
    {
      title: "Product Goals",
      sub_title: "Built to Last",
      hover_title_1: "+ Craftsmanship",
      hover_title_2: "+ Designed to Last",
      hover_title_3: "+Technical Efficacy",
      image: product_goal_icon,
      bg_image: images.product_goals,
    },
    {
      title: "Planet Goals",
      sub_title: "Responsible Making",
      hover_title_1: "+Responsible Materials",
      hover_title_2: "+ Considered Process",
      hover_title_3: "",
      image: plannet_goal_icon,
      bg_image: images.process_goals,
    },
    {
      title: "People Goals",
      sub_title: "Build Thriving Communities",
      hover_title_1: "+ Gender Parity",
      hover_title_2: "+ Health Equity",
      hover_title_3: "+ Accessibility",
      image: people_icon,
      bg_image: images.asset53,
    },
  ]

  const slider = [
    {
      title: "ISPO 2018 and CES 2017",
      content:
        "Awarded for our work in creating a state-of-the-art washable lighting system via LEDs that provides lighting up to 450 feet.next-to-skin structures without hindering functionality and long-term use.",
      image: images.award1,
    },
    {
      title: "ISPO 2019 and CES 2019",
      content:
        "Awarded for our collaboration with Odlo and Clim8 in creating an on-demand smart heating mid layer with temperature sensors integrated seamlessly for automatic regulation.",
      image: images.award2,
    },
  ]

  const slider3 = [
    {
      topic: "Pioneers",
      desc: "of Smart soft goods, with a portfolio of patented technologies.",
    },

    {
      topic: "Powered by",
      desc: "MAS Holdings – SA’s largest apparel-tech conglomerate",
    },

    {
      topic: "Best in class",
      desc: " DFM, DFA and DFX ",
    },

    {
      topic: "Eco-system",
      desc: "Leveraging broad capabilities and longstanding partners",
    },

    {
      topic: "Largest",
      desc: "smart apparel manufacturer",
    },

    {
      topic: "Low impact  ",
      desc: " Manufacturing processes and building towards circularity ",
    },
    {
      topic: "Speed  ",
      desc: " to Market Best production and delivery approach ",
    },
    {
      topic: "Excellence ",
      desc: "in quality Deep industry and cross-industry expertise ",
    },
    {
      topic: "Vertically   ",
      desc: "  integrated supply chain ",
    },
  ]

  const team = [
    {
      image: images.tiasha,
      name: "Tiasha Renganathan",
      title: "Chief Customer Officer",
      link: "https://www.linkedin.com/in/tiasha-renganathan-646b8095/",
      meet_text: "Meet the Marketing Team",
    },
    {
      image: images.vishwa,
      name: "Vishwa Aluthge",
      title: "Chief Product Officer",
      link: "https://www.linkedin.com/in/vishwaaluthge/",
      meet_text: "Meet the Product Team",
    },
    {
      image: images.ross,
      name: "Ross D Alcazar",
      title: "Chief Operations Officer",
      link: "https://www.linkedin.com/in/rossalcazar/",
      meet_text: "Meet the Operations Team",
    },
  ]

  return (
    <Layout>
      <Seo title="About" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Re-imagining
                  </div>
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    the future of
                    <div>textiles</div>
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Crafting a smarter future via consumer centricity
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/about/Banner.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={820}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-mint mb-4 uppercase text-center ">
                    EMPOWERING HUMAN EXPERIENCES
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Intertwining elements that embody
                    <div>the human experience</div>
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Born from innovation, we seek to intertwine elements that
                    embody
                    <div>
                      human experience and technology, to craft complete
                      next-to-skin
                    </div>
                    <div>solutions that transform the way we live.</div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24">
          <Fade bottom cascade>
            <div className="text-center text-4xl max-w-xs md:max-w-none mx-auto lg:text-6xl mb-4 px-5 text-mint">
              We are Purpose Driven
            </div>
            <div className="text-2xl mt-2 text-center max-w-4xl mx-auto px-5 text-white">
              “Redefining how technology feels by integrating the smart with the
              soft,
              <div>to positively impact everyday lives”</div>
            </div>
          </Fade>
          <div className="lg:flex-row flex-col ml-10 flex pt-20">
            <div className="w-full lg:w-1/2">
              <Fade lrft>
                <div className="w-full ">
                  {/* <BackgroundImage
                    {...images.purpose}
                    className="sm:py-72 py-40"
                  ></BackgroundImage> */}

                  <ReactPlayer
                    className="w-full sm:h-full "
                    url="https://www.youtube.com/watch?v=JDYu5p6pL7s"
                    controls={true}
                    width="100%"
                  />
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2 sm:text-left text-center px-5 sm:px-20 lg:py-0 py-14 flex flex-col justify-center">
              <Fade>
                <div className="">
                  <div className="text-4xl max-w-xl text-mint font-light mb-3 leading-tight">
                    Our Story
                  </div>

                  <Story_slider story={story} />

                  {/* <div className="text-xl max-w-xl text-white">
                    From NPI to scale manufacturing – we’ll help you take your
                    product to market as we build high-mix products from the
                    lowest volumes to full scaled manufacturing. With experience
                    in design for manufacturing (DFM) and DFX means we navigate
                    the complexities of new product launch.
                  </div> */}

                  <div className="flex justify-center sm:justify-start mt-4 sm:mr-20">
                    <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                      <ChevronLeftIcon width={20} />
                    </div>
                    <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                      <ChevronRightIcon width={20} />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <Fade bottom>
            <div className="container mx-auto mt-20">
              <div className="text-white text-xl uppercase sm:text-left text-center">
                Who we are
              </div>
              <AtglanceSlider slider3={slider3} mint />
            </div>
          </Fade>
        </div>
      </section>

      <section>
        <div className="bg-salmon py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="flex lg:flex-row flex-col mb-80 pb-96 lg:pb-10 xl:pb-20 ">
              <div className="w-full lg:w-4/12 sm:pr-10">
                <Fade bottom cascade>
                  <div className=" text-white lg:pb-0 pb-20 sm:text-left text-center">
                    <Fade top>
                      <div className="uppercase text-xl font-light mb-4">
                        AWARDS
                      </div>
                    </Fade>
                    <div className="text-4xl font-light text-dusk mb-4 lg:max-w-xs">
                      Revolutionary Thinking with Purpose based Design
                    </div>
                    <div className="text-xl lg:max-w-sm">
                      We have received numerous global accolades for our
                      proprietary wearable technology platforms and product
                      development. Our track record of innovation and foresight
                      is well recognized by startups and industry leaders alike.
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="w-full lg:w-7/12 pb-24">
                <div className="text-white absolute w-full">
                  <MainSlider slider_array={slider} />
                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className=" px-10 xl:px-20 2xl:px-40 py-24">
            <Fade bottom cascade>
              <div className="text-dusk">
                <div className="text-center text-xl sm:text-2xl font-firs-thin uppercase mb-4">
                  POWERED BY
                </div>
                <div className="text-center text-4xl lg:text-6xl mb-4">
                  MAS Holdings
                </div>
                <div className="text-2xl mt-2 text-center mx-auto">
                  MAS is Southeast Asia’s largest textile and apparel
                  manufacturer, with over 30 years of experience.
                  <div>
                    Through the expertise gained by working with some of the
                    largest global consumer brands, we have built
                  </div>
                  unparalleled knowledge of the human body, material science,
                  and textile wearability. We have evolved to
                  <div>
                    become more than just a manufacturer but a valued technology
                    partner. Softmatter is backed by MAS
                  </div>
                  and born through innovation to pioneer robust, sustainable,
                  and integrated textile innovations.
                </div>

                <div className="flex justify-center mt-10 px-0 2xl:px-60">
                  <ReactPlayer
                    className="w-full sm:h-full "
                    url="https://youtu.be/RbUAKdTTUII"
                    controls={true}
                    width="100%"
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 sm:text-left text-center overflow-hidden">
          <Fade bottom cascade>
            <div className="container mx-auto">
              <div className="text-mint text-4xl mb-10">Leadership</div>
              <div className="">
                <div className="flex lg:flex-row flex-col items-center">
                  <div className="">
                    <StaticImage
                      alt="Softmatter"
                      src="../images/about/Ulysses.png"
                      width={2000}
                    />
                  </div>
                  <div className="lg:mt-0 mt-10 lg:pl-10">
                    <div className="text-mint text-2xl mb-2">Ulysses Wong</div>
                    <div className="text-mint text-2xl mb-2">
                      Chief Executive Officer
                    </div>
                    <div className="text-white text-xl pb-2">
                      Ulysses leads the efforts in building and growing the
                      global business of Softmatter.
                    </div>
                    <div className="text-white text-xl mb-2">
                      Previously, Ulysses was the President and CEO of Enbi, a
                      Platinum Equity-owned global manufacturing and engineering
                      company. Prior to that, Ulysses held VP-level business and
                      customer engagement positions at international
                      corporations, including: Flex, Logitech, and IBM. Ulysses’
                      leadership roles have also included General Manager, Sales
                      and Marketing, Product Management, Supply Chain
                      Management, and Systems Engineering.
                    </div>
                    <div className="text-mint">
                      <a
                        href="https://www.linkedin.com/in/ulyssesvwong/"
                        target="_blank"
                      >
                        <GatsbyImage
                          className="w-8 mt-2"
                          image={images.li_mint}
                          alt="Softmatter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                  {team.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <GatsbyImage
                            className=""
                            placeholder="blurred"
                            image={item.image}
                            alt="Softmatter"
                          />
                        </div>
                        <div className="mt-4">
                          <div className="text-mint text-2xl">{item.name}</div>
                          <div className="text-white text-xl mb-1">
                            {item.title}
                          </div>
                          <div className="text-white text-base">
                            <a href={item.link} target="_blank">
                              <GatsbyImage
                                className="w-8 mt-2"
                                image={images.li_mint}
                                alt="Softmatter"
                              />
                            </a>
                          </div>
                          <button className="rounded-full px-4 py-2 border mt-6 border-mint text-mint text-base hover:bg-mint/20 hover:text-white duration-200">
                            {item.meet_text}
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Fade bottom>
                  <div className="mt-20">
                    <div className="text-mint text-4xl mb-10">Advisor</div>
                    <div className="">
                      <div className="flex lg:flex-row flex-col items-center">
                        <div className="">
                          <StaticImage
                            alt="Softmatter"
                            src="../images/about/Leadership/Don.png"
                            width={1750}
                          />
                        </div>
                        <div className="lg:mt-0 mt-10 lg:pl-10">
                          <div className="text-mint text-2xl mb-2">
                            Donald Jones
                          </div>
                          <div className="text-mint text-2xl mb-2">
                            Cardiff Ocean Group
                          </div>
                          <div className="text-white text-xl mb-2">
                            Donald Jones is a globally recognized leader in
                            digital medicine, digital therapies, technology
                            enabled health services and virtual-first healthcare
                            delivery. He is a sought out as a speaker, advisor
                            and board member. He works across the spectrum of
                            healthcare, pharmaceutical, medical device,
                            wearable, smart apparel, material science and
                            healthcare companies.
                          </div>
                          <div className="text-mint">
                            <a
                              href="https://www.linkedin.com/in/donald-jones-b71b8a2/"
                              target="_blank"
                            >
                              <GatsbyImage
                                className="w-8 mt-2"
                                image={images.li_mint}
                                alt="Softmatter"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-24">
            <Fade bottom cascade>
              <div className="text-dusk">
                <div className="text-center text-xl sm:text-2xl font-firs-thin uppercase mb-4">
                  OUR APPROACH TO
                </div>
                <div className="text-center text-4xl lg:text-6xl  mb-4">
                  Sustainability
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  At Softmatter, we are committed to creating sustainable value
                  for all our stakeholders: we continuously innovate to provide
                  the best solutions for our customers. We invest in the
                  development of our employees and partners, and apply
                  eco-efficient practices across all our business activities.
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-dusk rounded-3xl  hover:bg-dusk/90 duration-200 text-white text-2xl">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
            <Fade bottom cascade>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-3 mt-20">
                {sustainability.map((item, index) => {
                  return (
                    <div className="group" key={index}>
                      <div className=" relative overflow-hidden">
                        <div className="">
                          <BackgroundImage
                            {...item.bg_image}
                            className="py-44"
                          />
                        </div>
                        <div className="bg-dusk p-4 absolute bottom-0 duration-200 -mb-96 group-hover:mb-0 h-full w-full">
                          <div className="flex items-center justify-center w-full h-full">
                            <div>
                              <div className="text-white flex justify-center">
                                <div className="text-white">
                                  <img
                                    src={item.image}
                                    alt="softmatter"
                                    width={100}
                                  />
                                  {/* <GatsbyImage
                                    image={item.image}
                                    alt="Softmatter"
                                  /> */}
                                </div>
                              </div>
                              <div className="text-mint text-xl mt-2">
                                {item.hover_title_1}
                              </div>
                              <div className="text-mint text-xl mt-2">
                                {item.hover_title_2}
                              </div>
                              <div className="text-mint text-xl mt-2">
                                {item.hover_title_3}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-1 group-hover:bg-dusk bg-transparent mt-2 duration-200"></div>
                      <div className="mt-4 text-dusk cursor-pointer">
                        <div className="text-2xl mb-1 font-firs-medium group-hover:text-mint duration-200 text-dusk">
                          {item.title}
                        </div>
                        <div className="text-xl  text-mint">
                          {item.sub_title}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.mint_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-4xl sm:text-6xl mb-4">
                  Connect with
                  <div>Softmatter</div>
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <button className="px-8 py-4 bg-mint  hover:bg-salmon/90 duration-200 text-white text-2xl">
                    Contact Us
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AboutImages {
    hero: file(relativePath: { eq: "home/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    end_to_end_1: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/concept.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_2: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/configure.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_3: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/industrial.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_4: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/Manufacturing.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    end_to_end_5: file(
      relativePath: { eq: "home/Your end-to-end-Carousel/proprietary.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    dreem: file(relativePath: { eq: "home/Success-stories/Dreem.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Ulysses: file(relativePath: { eq: "about/Ulysses.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    purpose: file(relativePath: { eq: "about/purpose.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    award1: file(relativePath: { eq: "about/Awards/Awards1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    award2: file(relativePath: { eq: "about/Awards/Awards2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    leadership1: file(relativePath: { eq: "about/Leadership/Don.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    leadership2: file(relativePath: { eq: "about/Leadership/Ross.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    leadership3: file(relativePath: { eq: "about/Leadership/Tiasha.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    leadership4: file(relativePath: { eq: "about/Leadership/Ulysses.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    leadership5: file(relativePath: { eq: "about/Leadership/Vishwa.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    purpose1: file(
      relativePath: { eq: "about/Purpose-Images/Purpose_Image_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    purpose2: file(
      relativePath: { eq: "about/Purpose-Images/Purpose_image1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    purpose3: file(
      relativePath: { eq: "about/Purpose-Images/Purpose_Image2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Sustainability1: file(
      relativePath: { eq: "about/Sustainability/Asset53.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Sustainability2: file(
      relativePath: { eq: "about/Sustainability/Process_Goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Sustainability3: file(
      relativePath: { eq: "about/Sustainability/Product_Goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    banner: file(relativePath: { eq: "about/Banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    li_mint: file(relativePath: { eq: "social/li_mint.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default About
