import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

// install Swiper modules
SwiperCore.use([Navigation])

const Story_slider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              // direction={"vertical"}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              navigation={{
                nextEl: ".swiper-button-next-square",
                prevEl: ".swiper-button-prev-square",
              }}
              slidesPerView={1}
              speed={1000}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              spaceBetween={15}
              loop={true}
              modules={[Autoplay]}
            >
              {props.story.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="text-xl max-w-xl text-white">
                      {item.content1}
                    </div>
                    <div className="text-base mt-4 max-w-xl text-mint">
                      {item.green_text}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default Story_slider
